// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "bootstrap";

import "owl.carousel";

// custom

/* Force divs in slideshows to have a uniform height */

const matchHeight = function () {
    function matchHeight() {
        const groupName = jQuery("[data-match-height]");
        const groupHeights = [];

        groupName.css("min-height", "auto");

        groupName.each(function () {
            groupHeights.push(jQuery(this).outerHeight());
        });

        const maxHeight = Math.max.apply(null, groupHeights);
        groupName.css("min-height", maxHeight);
    }

    function eventListeners() {
        jQuery(window).on("resize", function () {
            matchHeight();
        });
    }

    function init() {
        eventListeners();
        matchHeight();
    }

    return {
        init
    };
}();

/* slideshows */

jQuery(document).ready(function () {
    jQuery(".professional_slider").owlCarousel({
        autoplay: true,
        lazyLoad: true,
        loop: true,
        dots: false,
        margin: 0,
        responsiveClass: true,
        autoHeight: false,
        autoplayTimeout: 7000,
        smartSpeed: 800,
        nav: true,
        responsive: {
            0: {
                items: 1
            },

            640: {
                items: 2
            },
            1024: {
                items: 4
            }
        }
    });

    jQuery(".project_slider").owlCarousel({
        dots: false,
        margin: 60,
        responsiveClass: true,
        autoHeight: false,
        autoplayTimeout: 7000,
        smartSpeed: 800,
        nav: true,
        responsive: {
            0: {
                items: 1
            },

            768: {
                items: 2
            },
            1024: {
                items: 3
            }
        }
    });
    matchHeight.init();
});
